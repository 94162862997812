import './Map.css';
import {useEffect, useState, useRef} from "react";
import mapboxgl from 'mapbox-gl';
import {useTranslation} from "react-i18next";


function Map(props) {
    const [map, setMap] = useState();
    const [markers, setMarkers] = useState(new Array(2));
    const cityToGuessRef = useRef(props.cityToGuess);
    const missDistanceRef = useRef(props.missDistance);
    const { t, i18n} = useTranslation();

    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoibWFuZ29tb3Vzc2UiLCJhIjoiY2tqdWMzeWp3MDFjbTJ1bGd5NWJmb3lrYiJ9.SIzsL0QeIRa66bFhqdXtgQ";

        const boundingBox = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(-24, 28.8250),
            new mapboxgl.LngLat(48.1401, 70.9286)
        );
        let map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mangomousse/ckjswly0182a219pa8zpkis7f',
            maxZoom: 7,
            maxPitch: 0,
            maxBounds: boundingBox,
            dragRotate: false,
            touchPitch: false
        });
        map.touchZoomRotate.disableRotation();

        if (window.self !== window.top) {
            // Change zoom control if the map is embedded in an iframe
            map.scrollZoom.disable();
            map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        }

        setMap(map);

        map.on('click', function(e) {
            if(map.getSource('route') === undefined && cityToGuessRef.current !== null){
                props.onMapClick(e);
            }
        });

        map.on('dragstart', function() {
            map.getCanvas().style.cursor = 'grabbing';
        });

        map.on('dragend', function() {
            map.getCanvas().style.cursor = 'crosshair';
        });

        map.on('load', function() {
            map.getCanvas().style.cursor = 'crosshair';
        });
    }, []);

    useEffect(() => {
        missDistanceRef.current = props.missDistance;
        cityToGuessRef.current = props.cityToGuess;
    }, [markers, map, props.cityToGuess, props.missDistance]);

    useEffect(() => {
        if(props.mapClickEvent !== null && map.getSource('route') === undefined && cityToGuessRef.current !== null){

            // Draw the guess
            let markerStart = new mapboxgl.Marker();
            markerStart.setLngLat([props.mapClickEvent.lngLat.lng, props.mapClickEvent.lngLat.lat])
                .addTo(map)
                .setPopup(new mapboxgl.Popup({closeOnClick: false, closeButton: false, focusAfterOpen: false, anchor: props.mapClickEvent.lngLat.lat > cityToGuessRef.current.Latitude ? 'bottom' : 'top'}).setHTML("<b>"+t('distance')+": "+missDistanceRef.current+" km</b>"))
                .togglePopup();
            // Draw actual location
            let markerEnd = new mapboxgl.Marker({color: "#177a06"});
            markerEnd.setLngLat([cityToGuessRef.current.Longitude, cityToGuessRef.current.Latitude])
                .addTo(map)
                .setPopup(new mapboxgl.Popup({closeOnClick: false, closeButton: false, focusAfterOpen: false, anchor: props.mapClickEvent.lngLat.lat > cityToGuessRef.current.Latitude ? 'top' : 'bottom'}).setHTML("<b>"+cityToGuessRef.current.Capital+", "+ cityToGuessRef.current.Country +"</b>"))
                .togglePopup();

            setMarkers([markerStart, markerEnd]);
            // Draw line between the points
            map.addSource('route', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': [
                            [props.mapClickEvent.lngLat.lng, props.mapClickEvent.lngLat.lat],
                            [cityToGuessRef.current.Longitude, cityToGuessRef.current.Latitude]
                        ]
                    }
                }
            });
            map.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#888',
                    'line-width': 8
                }
            });
        }
    }, [map, props.mapClickEvent]);

    useEffect(() => {
        if(map !== undefined && !props.capitalGuessed && markers[0] !== undefined ){
            if(map.getLayer('route') !== undefined){
                map.removeLayer('route');
                map.removeSource('route');
            }
            markers[0].remove();
            markers[1].remove();
        }
    }, [props.capitalGuessed, map, markers]);

    useEffect(() => {
        if(map !== undefined && props.mapStyle !== null){
            map.setStyle('mapbox://styles/mangomousse/' + props.mapStyle);
        }
    }, [props.mapStyle, map]);

    return (
    <div className="App">
      <div id="map">
      </div>
    </div>
  );
}

export default Map;
