import './GameStateOverlay.css';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {Button} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

function GameStateOverlay(props){

    const { t, i18n} = useTranslation();

    return (
        <div className="GameStateOverlay">
            <div className="GameStateOverlayHeader">

                <div className="NumberDisplays">
                    <div className="NumberDisplay">
                        <b>{t('numberPoints')}: {props.totalPoints}</b>
                    </div>
                    {props.newGame &&
                    <div className="GameName">
                        <b>Geo Dart</b>
                    </div>
                    }
                    <div className="NumberDisplay">
                        <b>{t('numberCities')}: {props.capitalGuessed ? props.citiesGuessed : props.citiesGuessed-1}/20</b>
                    </div>
                </div>

            </div>
            <div className="GameStateOverlayFooter">
                {props.endGame &&
                <div className="EndGame">
                    <p>
                        {t('endGame1')} <b>{props.totalPoints}</b> {t('endGame2')}
                    </p>
                </div>
                }
                {props.newGame &&
                    <div className="NewGame">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onNewGameButtonClick}
                            endIcon={<PlayArrowIcon/>}
                        >
                            {t('newGame')}
                        </Button>
                        <br/>
                        <br/>
                        <div className="gameOptions">
                            <div className="coloredCountriesOption">
                                <label>{t('coloredCountriesOption')}: </label>
                                <input type="checkbox" name="checkbox"
                                       defaultChecked={props.mapStyle === 'ckkjjadc71w2x17nuek0q4kww' ? true : false}
                                       onClick={props.onMapStyleChangeClick}/>
                            </div>
                            <div className="languageOption">
                                <label>{t('languageOption')}: </label>
                                <button onClick={() => props.onLanguageChangeClick('en')}>🇬🇧</button>
                                <button onClick={() => props.onLanguageChangeClick('de')}>🇩🇪</button>
                            </div>
                        </div>
                    </div>
                }
                {!props.newGame && props.capitalGuessed &&
                    <div className="LastGuess">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onNewCityButtonClick}
                            endIcon={<PlayArrowIcon/>}
                        >
                                {t('nextCityButton')}
                            </Button>
                    </div>
                }
                {!props.newGame && !props.capitalGuessed &&
                    <div className="NextGuess">
                        {t('guessCity')} <b>{props.cityToGuess.Capital}</b>?<br />
                    </div>
                }
            </div>
        </div>
    );
}

export default GameStateOverlay;
