import {useState, useRef} from "react";
import Map from "../Map/Map";
import GameStateOverlay from "../GameStateOverlay/GameStateOverlay";
import countriesEn from '../../CountriesAndCapitals_en.json';
import countriesDe from '../../CountriesAndCapitals_de.json';
import { useTranslation } from 'react-i18next';

function GameLogic() {
    const [countries, setCountries] = useState(countriesEn);
    const [capitalGuessed, setCapitalGuessed] = useState(true);
    const capitalGuessedRef = useRef(capitalGuessed);
    const [capitalList, setCapitalList] = useState([]);
    const capitalListRef = useRef(capitalList);
    const [missDistance, setMissDistance] = useState(0);
    const [cityToGuess, setCityToGuess] = useState(null);
    const cityToGuessRef = useRef(cityToGuess);
    const [newGame, setNewGame] = useState(true);
    const [endGame, setEndGame] = useState(false);
    const [showPoints, setShowPoints] = useState(false);
    const [gameCityCounter, setGameCityCounter] = useState(0);
    const gameCityCounterRef = useRef(cityToGuess);
    const [totalPoints, setTotalPoints] = useState(0);
    const totalPointsRef = useRef(totalPoints);
    const [mapClickEvent, setMapClickEvent] = useState(null);
    const [mapStyle, setMapStyle] = useState(null);

    const { t, i18n} = useTranslation();

    function calcDistance(lat1, lon1, lat2, lon2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    function onMapClick(e) {
        if(!capitalGuessedRef.current){
            // Calculate Distance to capital
            let guess = JSON.parse(JSON.stringify(e.lngLat));
            let distance = calcDistance(guess.lat, guess.lng, cityToGuessRef.current.Latitude, cityToGuessRef.current.Longitude);
            distance = distance-15 > 0 ? distance-15 : 0;
            // Update GameStateOverlay & Map
            setMissDistance(Math.round(distance));
            let currentPoints = (1000-Math.round(distance));
            currentPoints = currentPoints > 0 ? currentPoints : 0;
            setTotalPoints(totalPointsRef.current+currentPoints);
            totalPointsRef.current = totalPointsRef.current+currentPoints;
            setCapitalGuessed(true);
            capitalGuessedRef.current = true;
            setMapClickEvent(e);
        }
    }

    function onNewGameButtonClick(){
        setNewGame(false);
        setEndGame(false);
        setShowPoints(true);
        setGameCityCounter(0);
        gameCityCounterRef.current = 0;
        // Shuffle countries list
        let tempCapitalList = [];
        while(tempCapitalList.length !== 20) {
            let index = Math.round(Math.random()*(countries.length-1));
            if(!tempCapitalList.includes(countries[index])){
                tempCapitalList.push(countries[index])
            }
        }
        setCapitalList(tempCapitalList);
        capitalListRef.current = tempCapitalList;
        // Reset point counter
        setTotalPoints(0);
        totalPointsRef.current = 0;
        // Start game
        onNewCityButtonClick();
    }

    function onNewCityButtonClick() {
        if(gameCityCounterRef.current >= 20){
            setEndGame(true);
            setNewGame(true);
            setShowPoints(false);
            return;
        }
        setGameCityCounter(gameCityCounterRef.current+1);
        gameCityCounterRef.current++;
        // Clear Map & Update City to guess
        nextCityToGuess();
        setCapitalGuessed(false);
        capitalGuessedRef.current = false;
    }

    function nextCityToGuess() {
        setCityToGuess(capitalListRef.current[gameCityCounterRef.current-1]);
        cityToGuessRef.current = capitalListRef.current[gameCityCounterRef.current-1];
    }

    function onMapStyleChangeClick(e){
        if(e.target.checked){
            setMapStyle('ckkjjadc71w2x17nuek0q4kww');
        } else {
            setMapStyle('ckjswly0182a219pa8zpkis7f');
        }
    }

    function onLanguageChangeClick(lng){
        i18n.changeLanguage(lng);
        if(lng === 'de') {
            setCountries(countriesDe);
        } else {
            setCountries(countriesEn);
        }
    }

    return (
        <div>
            <Map
                cityToGuess={cityToGuess}
                onMapClick={onMapClick}
                capitalGuessed={capitalGuessed}
                missDistance={missDistance}
                mapClickEvent={mapClickEvent}
                mapStyle={mapStyle}
            />
            <GameStateOverlay
                newGame={newGame}
                endGame={endGame}
                showPoints={showPoints}
                citiesGuessed={gameCityCounter}
                onNewGameButtonClick={onNewGameButtonClick}
                totalPoints={totalPoints}
                cityToGuess={cityToGuess}
                onNewCityButtonClick={onNewCityButtonClick}
                capitalGuessed={capitalGuessed}
                missDistance={missDistance}
                onMapStyleChangeClick={onMapStyleChangeClick}
                mapStyle={mapStyle}
                onLanguageChangeClick={onLanguageChangeClick}
            />
        </div>
    );
}

export default GameLogic;