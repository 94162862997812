import './App.css';
import GameLogic from "./components/GameLogic/GameLogic";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/purple';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#289e1a',
        },
        secondary: amber,
    },
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <GameLogic />
            </div>
        </MuiThemeProvider>
  );
}

export default App;
